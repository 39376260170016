const ApiRouteList = {
    GET_APP_INFO: 'app-info',
    ADMIN: 'admin',
    USER: 'user',
    ADMINS:'admins',
    COURSE : `course`,
    APPS: 'apps',
    TRAINING_CONTENT : `trainingContent`,
    SUBSCRIPTION : `/courseSubscription`,
    GET_AUTH_CODE: `get-auth-code`,
    RESET_PASSWORD: `resetPassword`,
    VERIFY_AUTH_CODE: `verify-auth-code`,
    FORGET_PASSWORD: `forgotPassword`,
    LESSON_VIEW : 'admin/contentCompleted',
    VIEW_COMPLETED : '/view-completed' ,
    COMPANIES : '/companies',
    CONTACT : '/contacts',
    ACTIVITIES : '/activities',
    EMAILFORMAILERS : '/mailers',
    MARKETING_CAMPAIGN : '/campaigns',
    MARKETING_EMAIL : '/campaign/emails',
    GROUPS : '/groups',
    CRONJOB : '/cron/jobs',
    CRON : '/cron',
    JOBS : '/jobs',
    MAILERSCSV : 'mailers/csv',
    RELATEDCOMPANIES : '/companies/list',
};
export default ApiRouteList;